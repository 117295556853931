//@include media-breakpoint-up(lg) {

    #sidebar.sidebar-large {
        width: $sidebar-large-width;
        @include media-breakpoint-down(sm) {
            width: $sidebar-smaller-width;
        }
    }

    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-large.sidebar-expanded .content-header,
    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-large.sidebar-expanded:not(:hover) .content-header,
    #page-container.side-scroll #sidebar.sidebar-large .content-side {
        width: $sidebar-large-width !important;
        @include media-breakpoint-down(sm) {
            width: $sidebar-smaller-width !important;
        }
    }

    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-large .content-header {
        width: $sidebar-large-mini-width !important;
    }

    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-large:not(:hover):not(.sidebar-expanded) .content-header {
        width: $sidebar-large-mini-width !important;
    }
    .sidebar-mini.sidebar-o #sidebar.sidebar-large:not(.sidebar-expanded) {
        transform: translateX(-($sidebar-large-width - $sidebar-large-mini-width)) translateY(0) translateZ(0);
        @include media-breakpoint-down(sm) {
            transform: translateX(-($sidebar-smaller-width - $sidebar-large-mini-width)) translateY(0) translateZ(0);
        }
    }
    
    .sidebar-mini.sidebar-o #sidebar.sidebar-large:not(.sidebar-expanded) .content-side, 
    .sidebar-mini.sidebar-o #sidebar.sidebar-large:not(.sidebar-expanded) .content-header {
        transform: translateX($sidebar-large-width - $sidebar-large-mini-width) translateY(0) translateZ(0);
        @include media-breakpoint-down(sm) {
            transform: translateX($sidebar-smaller-width - $sidebar-large-mini-width) translateY(0) translateZ(0);
        }
    }
    .sidebar-large {
        .logo-wrapper {
            width: $sidebar-large-mini-width;
            height: $sidebar-large-mini-width;
        }
        .nav-main-link {
            padding-left: 11px;
            padding-right: 11px;
            font-size: 1rem;
            .nav-main-link-icon {
                width: 40px;
                height: 40px;
                font-size: 1rem;
                margin-right: 11px;
                margin-left: 4px;
            }
        }
        
        & + .sidebar-submenu {
            .nav-main-link {
                font-size: 0.9375rem;
                
            }    
        }
            
        &:not(.sidebar-expanded) + .sidebar-submenu {
            left: $sidebar-large-mini-width;
        }
        &.sidebar-expanded + .sidebar-submenu {
            left: $sidebar-large-width;
            @include media-breakpoint-down(sm) {
                left: $sidebar-smaller-width;
            }
        }
    }
//}