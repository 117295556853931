
.custom-select-container {
    position: relative;
    box-shadow: none !important;
}
.custom-select__button-container {
    width: 100%;
    z-index: 1;
    display: inline-block;
}
.custom-select__menu-container {
    position: absolute;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    background-color: #fff;
    z-index: 10;
}

.custom-select__placeholder {
    background-color: transparent;
}

.custom-select__button {
    border-color: $input-border-color !important;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    min-height: 38px;
    padding-right: 30px;

    &:hover {
        background-color: #fff !important;
    }
    
    &__green & {
        border-color: $custom-green !important;
        background-color: $custom-green !important;
        color: $white;
        &:hover, &:focus {
            border-color: shade-color($custom-green, $btn-hover-bg-shade-amount) !important;
            background-color: shade-color($custom-green, $btn-hover-bg-shade-amount) !important;
            color: $white;
        }
        &:focus {
            box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width rgba($custom-green, $input-btn-focus-color-opacity);
        }
        .fa-caret-down {
            color: $white !important;
        }
    }
}



.custom-select__caret {
    position: absolute;
    pointer-events: none;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.custom-select__option {
    position: relative;
    cursor: pointer;
    padding: 8px 36px 8px 12px;
    font-size: 14px;
}
.custom-select__opt-group {
    opacity: 0.6;
}

.custom-select__option--hovered {
    background: $body-bg;
}
.custom-select__option--selected {
    background-color: $primary;
    color: $white;
}

.custom-select__selectedicon {
    position: absolute;
    padding-right: 16px;
    right: 0;
    color: $primary;
    display: flex;
    align-items: center;
    svg {
        height: 1.25rem;
        width: 1.25rem;

        &:first-child {
            fill: #fff;
        }
        &:last-child {
            stroke: #fff;
        }
    }
}

.custom-select__menu {
    list-style: none;
    padding: 0px;
    &:focus {
        outline: none;
    }
}

.custom-select-clear {
    background: transparent;
    padding: 0;
    color: $primary;
    border: none;
    border-radius: 100%;
    position: absolute;
    right: 2rem;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
        background-color: $primary;
        color: #fff;
    }
    svg {
        height: 16px;
        width: 16px;
    }
}

.custom-select__option-display__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



///customized customselect///@at-root

.customizedselect {
    .custom-select__button {
        &:hover, &:focus {
            background-color: transparent !important;
        }
    }
    .custom-select__option {
        color: $body-color;
    }
    
    .custom-select__option--hovered {
        background: $body-bg-light;
        color: $body-color;
    }
    .custom-select__option--selected {
        background-color: shade-color($body-bg,5) !important;
        //background-color: $body-bg;
    }

    .custom-select__selectedicon svg {
        display: none !important;
    }

    .custom-select__menu {
        padding: 0 !important;
    }

    .custom-select__menu-container {
        transform: none !important;
    }

    
}
