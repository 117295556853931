.modal.fade.right {
    // to prevent modalbackdrop overflow
     overflow: visible; 
    .modal-dialog {
        margin: 0 0 0 auto;
        height: 100%;
        background-color: #fff;
        pointer-events: auto;
        overflow: auto;
    }

    &:not(.show) {
        .modal-dialog {
            transform: translate(100%, 0px);
        }
    }
}

