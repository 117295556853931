.sidebar-dark {
    &.sidebar-type-2 {
        #sidebar {
            .nav-main-link:hover {
                color: var(--nav-main-link-hover-dark-mode);
            }
        
            .nav-main-item.open {
                .nav-main-link {
                     color: rgba($body-color-light, .75);
                    .nav-main-link-icon {
                        background-color: $primary;
                        color: $white;
                    }
                    &:hover .nav-main-link-icon{
                        //color: rgba($body-color-light, .75);
                        color: $white;
                    }
                }
            }

            .nav-main-item.current > .nav-main-link {
                color: $primary;
                font-weight: bold;
                .nav-main-link-icon {
                    color: $primary;
                    background: none;
                }
                &:hover {
                    color: $primary; 
                    .nav-main-link-icon {
                        color: $primary;    
                    }
                }
            }
        
            .nav-main-link {
                .nav-main-link-icon {
                    background-color: var(--nav-main-link-icon-bg-dark-mode);
                    color: rgba($body-color-light, .75);
                }
                &:hover .nav-main-link-icon {
                    color: $primary;
                }
            }
            // &.sidebar-modern {
            //     .nav-main-link-icon:hover {
            //         background-color: var(--nav-main-link-hover-bg-dark-mode);
            //         color: rgba($body-color-light, .75);
            //     }
            // }
        }
        .sidebar-submenu {
            box-shadow: none;
        
            &__inner {
                color: $body-color-light;
                background-color: shade-color($sidebar-dark-bg, 20%);
            }
        }
        .nav-main-submenu .nav-main-link {
            color: rgba($body-color-light, .75);
            font-weight: 400;

            &:not(.active):hover {
                background-color: var(--nav-main-link-hover-bg-dark-mode);
                color: rgba($body-color-light, .75) !important;
            }
            &.active, &.active:hover  {
                color: $primary !important;
                font-weight: bold;
                
            }
        }
    }

    &.sidebar-type-1 {
        #sidebar {
            .nav-main-link {
                &:not(.active):hover {
                    background-color: shade-color($sidebar-dark-bg, 20%);
                    color: rgba($body-color-light, .75);
                    .nav-main-link-icon {
                        color: rgba($white, .3);
                    }
                }
                &.active {
                    background-color: rgba($primary,0.08);
                    color: $primary;
                    font-weight: bold;
                    &:before {
                        background: $primary;
                    }
        
                    &:hover {
                        color: $primary;
                    }
                }
            }
            
            .nav-main-item.open {
                & > .nav-main-link-submenu {
                    color: #fff;
                    font-weight: 500;
                    &:hover {
                        color: #fff;
                    }
                    & > .nav-main-link-icon {
                        color: lighten($body-color, 40%);
                    }
                }
        
                &:not(.nav-main-link-submenu) {
                    .nav-main-link {
                        // background-color: rgba($primary,0.08);
                        // color: $primary;
                        
                        // &-icon {
                        //     color: $primary;
                        // }
                        // &:before {
                        //   background: $primary;
                        // }
        
                        // &:hover {
                        //     color: $primary;
                        // }
                    }
                }
            }
            .nav-main-item.current > .nav-main-link-submenu {
                color: $primary;
                font-weight: bold;
                .nav-main-link-icon {
                    color: $primary;
                }
                &:hover {
                    color: $primary; 
                }
            }
            .nav-main-submenu {
                background-color: #fff;
                .nav-main-link {
                    
                    &:not(.active):hover {
                        color: rgba($body-color-light, .5);
                    }
                }
            } 
        }
    }
    
}