//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and Codebase functions/mixins/styles etc
// --------------------------------------------------

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';

.main-content-boxed {
    position: relative;

    .auth-container {
        position: relative;

        .auth-bg {
            img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
        }

        header {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
        }

        .hero-static {
            padding-top: 112px;

            .logo-wrapper {
                width: 50%;
                padding-top: min(20%, 120px);
                position: relative;
                height: auto;
                min-width: 260px;

                //max-width: 300px;
                .sidebarlogo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .input-group {
                .input-group-text {
                    border-right: 0;
                    background-color: $white;
                }

                input {
                    border-left: 0;
                }
            }
        }
    }
}

.aico__image-column {
    img {
        object-fit: cover !important;
        width: 60px;
        height: 60px;
    }
}

#page-container {
    width: calc(100vw - 1em);
}

.w-85 {
    width: 95px !important;
}

.pac-container {
    z-index: 1056;

    &:empty {
        border: none;
        box-shadow: none;
    }
}

.w-110 {
    //width: 110px !important;
}

.modal-dialog .modal-content {
    background-color: #fff;
}

.collection-conditions {
    width: max-content;
    padding: 10px
}

.text-right {
    text-align: right !important;
}

.dashbord-table{
    td{
        height: 85px;
    }
}
.dashboard-block {
    padding: 1rem 1.9375rem;
    .fs-big{
        font-size: 2.1875rem;
        line-height: 2.2rem
    }
    .fs-small{
        font-size: 1.375rem;
    }
}


.sales-chart-dropdown {
    .form-control {
        padding-right: 2.5rem;
    }
}

.blue-circle{
    background-color: #ebf4ff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .color-icon {
            color: #7f9cf5;
            font-size: 1rem;
        }
}

.dropdown-assignee {
    .d-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.dataTable {
    .list-unstyled {
        margin-bottom: 0;
    }
}

.carousel-indicators [data-bs-target] {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin: 0 5px;
}

.sales-table {
    .sorting {
        &:after {
            top: 23px;
        }
    }
}

.dataTable {
    .status-bg {
        background: #ebf4ff;
        border-radius: 0.25rem;
        min-width: 90px;
    }
}

.task-bg {
    background: $white;
    border: 1px solid #e4e7ed !important;
    border: 1px solid #e4e7ed !important;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    padding: 0.5rem 0.5rem 0.3rem;
    border-radius: 0.25rem;

    .color-holder {
        margin-right: 5px;
    }

    .d-date {
        font-weight: 400;
        display: block;
        width: 100%;
        height: auto;
    }
}