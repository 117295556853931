.modalpopup {
    $parent: &;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    outline: 0;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    @media (min-width: 1000px) {
        pointer-events: none;
    }

    &__inner {
        width: 100%;
        max-height: 90%;
        margin: auto;
        pointer-events: auto;
        background-color: $white;
        max-width: 1000px;
        border-radius: 12px;
        overflow-y: visible;
    }

    &.modal-small {
        #{$parent}__inner {
            max-width: 764px;
        }
    }
}
