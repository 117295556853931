.logo-wrapper {
    width: $sidebar-mini-width;
    height: $sidebar-mini-width;
}

.sidebarlogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.simplebar-track.simplebar-horizontal {
    display: none;
}

.collapse-icon {
    position: absolute;
    top: 30px;
    left: -9.5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: #fff;
    font-size: 12px;
    border-radius: 100%;
    z-index: 51;
    cursor: pointer;
}

#sidebar .content-header {
    padding-left: 0;
    padding-right: 0;
}

#page-container.side-scroll #sidebar {
    overflow-y: hidden;
}
#page-container.side-scroll:not(.sidebar-dark) #sidebar {  
    border-right: 1px solid #ddd;
}


#sidebar .js-sidebar-scroll {
    height: calc(100vh - #{$header-height} - #{$fixedmenu-height});
    overflow-x: hidden;
}

#sidebar .content-side.content-side-full {
    padding-top: 0;
    padding-bottom: 0;
}

.content-side-bottom {
    .nav-main-item, .nav-main-item.open {
        .nav-main-link-submenu:after {
            content: none;
        }
        .nav-main-link-submenu:before {
            opacity: 1;
            transform: rotate(-90deg);
        }
    }
}


//@include media-breakpoint-up(lg) {
    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-expanded {
        padding-left: $sidebar-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width;
        }
    }
    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-second-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-second-sidebar-expanded {
        padding-left: $sidebar-mini-width + $sidebar-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-mini-width + $sidebar-smaller-width;
        }
    }

    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-second-sidebar-expanded.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-second-sidebar-expanded.has-sidebar-expanded {
        padding-left: $sidebar-width + $sidebar-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width + $sidebar-smaller-width;
        }
    }

    //compact

    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-compact.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-compact.has-sidebar-expanded {
        padding-left: $sidebar-compact-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width;
        }
    }
    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-compact.has-second-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-compact.has-second-sidebar-expanded {
        padding-left: $sidebar-compact-mini-width + $sidebar-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-compact-mini-width + $sidebar-smaller-width;
        }
    }

    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-compact.has-second-sidebar-expanded.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-compact.has-second-sidebar-expanded.has-sidebar-expanded {
        padding-left: $sidebar-compact-width + $sidebar-compact-width;

        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width + $sidebar-smaller-width;
        }
    }

    //large


    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-large.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-large.has-sidebar-expanded {
        padding-left: $sidebar-large-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width;
        }
    }
    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-large.has-second-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-large.has-second-sidebar-expanded {
        padding-left: $sidebar-large-mini-width + $sidebar-large-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-large-mini-width + $sidebar-smaller-width;
        }
    }

    #page-container.page-header-fixed.sidebar-mini.sidebar-o.has-sidebar-large.has-second-sidebar-expanded.has-sidebar-expanded #page-header,
    #page-container.sidebar-mini.sidebar-o.has-sidebar-large.has-second-sidebar-expanded.has-sidebar-expanded {
        padding-left: $sidebar-large-width + $sidebar-width;
        @include media-breakpoint-down(sm) {
            padding-left: $sidebar-smaller-width + $sidebar-smaller-width;
        }
    }
//}


// styles to turnoff default hover functionality of theme

@include media-breakpoint-up(lg) {

    .sidebar-mini.sidebar-o #sidebar:hover {
        .content-header,.content-side {
            transform: translateX($sidebar-width - $sidebar-mini-width) translateY(0) translateZ(0);
        }
    }

    .sidebar-mini.sidebar-o #sidebar:hover {
        transform: translateX(-($sidebar-width - $sidebar-mini-width)) translateY(0) translateZ(0);
    }

    #page-container.sidebar-mini.sidebar-o #sidebar .content-header {
        width: $sidebar-mini-width !important;
        margin-left: 0;
    }


    .sidebar-mini.sidebar-o #sidebar:hover .nav-main .nav-main-link-icon {
        transform: translateX(0.75rem);
    }


    .sidebar-mini.sidebar-o #sidebar:hover .nav-main {
        transform: translateX(-1rem);
    }

    .sidebar-mini.sidebar-o #sidebar:hover .nav-main .nav-main-link-name {
        opacity: 0;
    }

    .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main > .nav-main-item > .nav-main-submenu {
        display: block;
    }

    .sidebar-mini.sidebar-o #sidebar:hover .nav-main > .nav-main-item > .nav-main-submenu {
        //display: none;
    }
}


//do it with sidebar-expanded class instead

// @include media-breakpoint-up(lg) {
    
    .sidebar-mini.sidebar-o #sidebar.sidebar-expanded {
        .content-header,.content-side {
            transform: translateX(0px);
        }
    }

    .sidebar-mini.sidebar-o #sidebar.sidebar-expanded {
        transform: translateX(0px);
    }

    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-expanded .content-header,
    #page-container.sidebar-mini.sidebar-o #sidebar.sidebar-expanded:not(:hover) .content-header {
        width: $sidebar-width !important;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            width: $sidebar-smaller-width !important;
        }
    }

    .sidebar-mini.sidebar-o #sidebar.sidebar-expanded .nav-main .nav-main-link-icon {
        transform: translateX(0);
    }

    .sidebar-mini.sidebar-o #sidebar.sidebar-expanded .nav-main {
        transform: translateX(0);
    }

    .sidebar-mini.sidebar-o #sidebar.sidebar-expanded .nav-main .nav-main-link-name {
        opacity: 1;
        
    }

    .sidebar-wrapper {
        position: relative;
    }
    .resizer-r {
        transition: background .2s cubic-bezier(.785,.135,.15,.86) .1s;
        position: absolute;
        top: 0;
        height: 100%;
        width: 9px;
        cursor: col-resize;
        right: -5px;
        opacity: 0.6;
        z-index: 50;
        display: none;
        &:hover {
            background-color: $primary;
        }
    }


    #page-container.sidebar-mini.sidebar-o #sidebar:hover .content-header .sidebarlogo {
        max-height: 4rem !important;
    }
//}