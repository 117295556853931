.litepicker {
    --litepicker-day-color: #{$input-color};
    --litepicker-button-prev-month-color-hover: #{$primary};
    --litepicker-button-next-month-color-hover: #{$primary};
    --litepicker-is-start-color-bg: #{$primary};
    --litepicker-is-today-color: #{$info};
    --litepicker-is-end-color-bg: #{$primary};
    //--litepicker-is-in-range-color: #{lighten($primary, 10%)};
    --litepicker-day-color-hover: #{$primary};
    --litepicker-month-header-color: #{$secondary};


    .button-previous-month, .button-next-month {
        cursor: pointer;
    }

    .container__days .day-item.is-today {
        font-weight: bold;
    }
}

.datepicker-wrapper {
    display: grid;
    grid-gap: 30px;
    box-shadow: none !important;
    grid-template-columns: repeat(2,1fr);
    & > .form-input {
        flex: 1 1 auto;
        @extend .form-control;
    }
}


// .litepicker .container__months .month-item-header .button-next-month:hover {
//     color: $primary;
// }

// .litepicker .container__months .month-item-header .button-next-month:hover>svg {
//         fill: $primary !important;
// }




