$step-height: 40px;

//variations can be made
$step-sm-height: 30px;
$step-md-height: 50px;
$step-lg-height: 60px;

.steps-wrapper {
    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
    }
    .step {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: $step-height;
        min-width: 100px;
        border-top:  1px solid $gray-500;
        border-bottom:  1px solid $gray-500;
        font-size: 16px;
        text-align: center;
        border-radius: 0;
        position: relative;
        overflow: visible;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 260px;

        &.step-less-width {
            max-width: 200px;            
        }

        &:first-child {
            border-left:  1px solid $gray-500;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        

        .form-check-input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            top: 0;
            left: 0;
            margin-top: 0;
            border-radius: 0;
            cursor: pointer;
        }
        &:before {
            content: "";
            width: calc($step-height*70/100);
            height: calc(70% + 2px);
            right: calc($step-height*-70/100);
            top: -1px;
            position: absolute;
            border: 1px solid;
            transform: rotate(45deg);
            transform-origin: top left;
            border-top-right-radius: 6px;
            border-color: $gray-500 $gray-500 transparent transparent;
            background-color: $white;
            z-index: 1;

        }
        &.dynamic-bg-color {
            .step-text {
                color: $white;
            }
            &:before {
               background-color: var(--bg-color);            
            }
            &:not(:last-child):before {
                 border-color: $white $white transparent transparent;
            }
        }

        &.active {
            background: $primary;
            color: $white;
            &::before{
                background: $primary;
                border-color: $primary $primary transparent transparent;
            }
        }

        &-text {
            padding-left: calc($step-height*56/100);
            z-index: 1;
            width: 100%;
            &-overflow {
                margin-right: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .project_count {
                position: absolute;
                left: 50%;
                bottom: 0%;
                transform: translateX(-50%);
            }
        }
        
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 1.1;
            min-width: 90px;
            &-wrap {
                display: block;
            }
        }
        &.step-md {
            height: $step-md-height;
            .step-text {
                padding-left: calc($step-md-height*56/100);
            }
            &:before {
                width: calc($step-md-height*70/100);
                right: calc($step-md-height*-70/100);
            }
        }
        &.step-sm {
            height: $step-sm-height;
            .step-text {
                padding-left: calc($step-sm-height*56/100);
            }
            &:before {
                width: calc($step-sm-height*70/100);
                right: calc($step-sm-height*-70/100);
            }
        }
        &.step-lg {
            height: $step-lg-height;
            min-width: $step-lg-height*2.5;
            .step-text {
                padding-left: calc($step-lg-height*56/100);
            }
            &:before {
                width: calc($step-lg-height*70/100);
                right: calc($step-lg-height*-70/100);
            }
        }
    }
}

//for round steps

.roundsteps {
    --prm-color: #0284c7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    .step-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--prm-color);
        background-color: #FFF;
        transition: .4s;
        color: var(--prm-color);
        &[data-active] {
            background-color: var(--prm-color);
            color: #fff;
        }
        &-icon {
            display: none;
        }
    }
    .statusname {
        max-width: 110px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: calc(100% - 10px);
        left: 50%;
        transform: translateX(-50%);
    }
    .done {
        color: var(--prm-color);
        background-color: #fff;
        border: 1px solid var(--prm-color);
        .step-button-icon {
            display: inline;
        }
        .step-button-text {
            display: none;
        }
    }
    .round-step-item {
        z-index: 10;
        text-align: center;
        position: relative;
        padding-bottom: 10px;
    }
    #progress {
        -webkit-appearance:none;
        position: absolute;
        width: 100%;
        z-index: 5;
        top: 25px;
        height: 2px;
        left: 0;
        margin-bottom: 18px;
    }
    /* to customize progress bar */
    #progress::-webkit-progress-value {
        background-color: var(--prm-color);
        transition: .5s ease;
    }
    #progress::-webkit-progress-bar {
        background-color: var(--prm-color);
    }
    .substatuscolor {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: var(--bg-color);
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }
    .dropdown-menu::before {
        border-bottom: 9px solid rgba(0, 0, 0, 0.2);
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 5%; /* position */
        position: absolute;
        top: -8px;
    }
    
    .dropdown-menu::after {
        border-bottom: 8px solid #FFFFFF;
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 5%; /* position */
        position: absolute;
        top: -7px;
    }
    
}








    


