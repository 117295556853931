.manual-book {
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;
    }
    &-inner {
        flex: 1 1 500px;
        margin: 0 7.5px 20px;
        display: flex;
        flex-direction: column;
    }
}

.sollhabenselection {
    max-width: 526px;
}

.max-height-custom-select {
    max-height: 200px;
}

.min-width-input {
    min-width: 140px;
}

.select2-container.sollhabenselection {
    width: 100%;
}

.select2-dropdown.sollhabendropdown .select2-results__option {
    strong {font-weight: 500;}
    padding: 8px 11px;
}

.select2-selection__rendered {
    display: flex;
    align-items: center;
}

.select2-dropdown.sollhabendropdown .selection-right {
    color: #666666;
}

.select2-dropdown.sollhabendropdown .select2-results__option[aria-disabled=true] .selection-right {
    color: #999;
}

.select2-container--default.sollhabenselection .select2-selection__choice {
    background-color: transparent;
    color: #252525;
    font-weight: 400;
    padding: 0;
    .select2-selection__choice__remove {
        display: none;
    }
}

.select2-container .select2-dropdown.sollhabendropdown  {
    margin-top: 10px;
    padding: 20px;
    width: 526px !important;
    color: #252525;
    font-size: 15px;
    box-shadow: 0px 1px 17px #0000001F;
}

.select2-container--default .sollhabendropdown  .select2-results__option--highlighted[aria-selected] {
    background-color: #F2F9FF;
    color: #252525;
}
.select2-container--default .sollhabendropdown  .select2-results__option--highlighted.pe-none[aria-selected] {
    background-color: transparent;
}

.modalattachbtn {
    border-color: $input-border-color; 
    margin-right: 7px;
    position: relative;
}

.attachment-reciept__header {
    padding: 20px 35px;
    font-size: 20px;
    font-weight: 500;
    color: $theme-pulse-darker;
    border-bottom: 1px solid #dfdfdf;
}

.attachment-reciept__body {
    padding: 30px 35px;
}

.infobtn {
    color: #B2B3B6;
}

.greytext {
    color: $custom-grey-light;
}

.no-pointer-events {
    pointer-events: none;
}

.checked-circle {
    width: 15px;
    height: 15px;
    font-size: 10px;
    background-color: $success;
    color: $white;
    position: absolute;
    border-radius: 100%;
    top: -7.5px;
    right: -7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    
        