#assignee-btn {
    padding: 4px 10px;
}

.assignee-plus {
    border: 1px dashed $gray-400;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}


.assignee-plus__inner {
    border: 1px solid $gray-400;
    border-radius: 100%;
    width: 28px;
    height: 28px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

#assignee-btn::after {
    border-top-color: #3e444a;
    border-bottom-color: #3e444a;
}

.assignee-img {
    border: 1px dashed $gray-400;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}