.dropdown-item:hover .dropdown-closeicon {
    visibility: visible;
}

.dropdown-closeicon {
    width: 10px;
    height: 10px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: $white;
    background-color: $danger;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    i {
        font-size: 8px;
        margin-top: 1px;
    }
}

.watcher-topcaret {
    top: -3px;
    right: 13px;
}