.custom-vanilla-dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    border-radius: 8px;
    width: auto !important;
    overflow: hidden;
    padding: 8px;

    hr {
        &:first-child {
            display: none;
        }
        margin-top: 4px !important;
        margin-bottom: 4px !important;
        background-color: $gray-600 !important;
    }

    & > div {
        white-space: nowrap;
        padding: 0 !important;
        
        span {
            display: block;
            padding: 8px 10px;
            &:empty {display: none;}
        }
        &:hover {
            background-color: $body-bg !important;
            white-space: nowrap;
            border-radius: 8px;
        }
    }
}