//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #3b5998;
$enable-negative-margins: true;
$custom-green: #1CA11C;
$white: #fff;
$gray-100: #F5F8FB;
$gray-550: #B2B3B6;

$space-main-max-width: initial;
$fixedmenu-height: 52px;

$sidebar-mini-width: 55px;
$sidebar-width: 210px;


$sidebar-compact-mini-width: 50px;
$sidebar-compact-width: 210px;


$sidebar-large-mini-width: 62px;
$sidebar-large-width: 230px;
$side-transition: 0s;

$sidebar-smaller-width: 150px;



$table-color: #666;
$table-hover-bg: #F2F9FF;

//custom variables


$custom-grey: #666;
$custom-grey-light: #B2B3B6;
$theme-custom-elegance: #7B69B9;
$theme-custom-yellow: #DCA540;
$theme-custom-green:#7FB548;
$theme-custom-red:#E27764;

$form-switch-bg-green-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-green}'/></svg>") !default;

$form-switch-focus-bg-green-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-green}'/></svg>") !default;
$form-switch-checked-bg-green-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>") !default;







//codebase override

$space-block: 1rem;

