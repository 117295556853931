#sidebar, .sidebar-submenu {
    --nav-main-link-hover-bg: #{$body-bg};
    //--nav-main-link-icon-bg: #{tint-color($body-bg,45%)};
    --nav-main-link-icon-bg: transparent;
    
    //dark mode vars
    --nav-main-link-hover-dark-mode: rgba(228, 231, 237, 0.75);
    --nav-main-link-hover-bg-dark-mode: #{$sidebar-dark-bg};
    --nav-main-link-icon-bg-dark-mode: transparent;
    --nav-main-link-icon-dark-mode: #{lighten($body-color, 5%)};
}
    

@import 'sidebar/sidebar_responsive';
@import 'sidebar/sidebar_layout_override';
@import 'sidebar/sidebar-common';
@import 'sidebar/sidebar-normal';
@import 'sidebar/sidebar-dark';
@import 'sidebar/sidebar-compact';
@import 'sidebar/sidebar-large';
@import 'sidebar/sidebar-modern';
@import 'sidebar/sidebar-dropdown-bottom';



    

        


   

