.form-switch {
    min-height: 1.2em;
    line-height: 1.2em;

    .form-check-input {
        height: 1.2em;
    }
}


.form-check {
    &.green &-input {
        background-image: escape-svg($form-switch-bg-green-image);
    }
    &.green &-input:focus {
        background-image: escape-svg($form-switch-focus-bg-green-image);
        border-color: $custom-green;
        outline: 0;
        box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width rgba($custom-green, $input-btn-focus-color-opacity);
    }
    &.green &-input:checked {
        background-color: $custom-green;
        border-color: $custom-green;
        background-image: escape-svg($form-switch-checked-bg-green-image);
    }

}
