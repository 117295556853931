.btn {
    white-space: nowrap;
}
//add custom variants in our own customizations
.btn-elegance-primary {
    @include button-variant($theme-elegance-primary, $theme-elegance-primary);
}
.btn-outline-elegance-primary {
    @include button-outline-variant($theme-elegance-primary);
}
.btn-elegance-light {
    @include button-variant($theme-elegance-light, $theme-elegance-light);
}
.btn-outline-elegance-light {
    @include button-outline-variant($theme-elegance-light);
}
.btn-outline-alt-secondary {
    @include button-outline-variant($body-bg);
}

//remove icon color if combined with outline variants
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        &.btn-minimal {
            i {
                color: $value !important;
            }
            &:hover i {
                color: color-contrast($value) !important
            }
        }
        &.add-to-collection, &.remove-from-collection {
            color: $value !important;
            &:hover  {
                color: color-contrast($value) !important
            }
        }
    }
}


 