//
// Hero
// --------------------------------------------------

.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;

  &.hero-sm {
    min-height: 300px;
  }

  &.hero-lg {
    min-height: 800px;
  }

  &-inner {
    flex: 0 0 auto;
    width: 100%;
  }

  &-meta {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 0;
    text-align: center;
  }
}

.hero-static {
  min-height: 100vh;
}

.hero-bubbles {
  position: relative;
  overflow: hidden;

  .hero-bubble {
    position: absolute;
    display: inline-block;
    border-radius: 100%;
    opacity: .1;
    z-index: 0;
    width: 2rem;
    height: 2rem;
    filter: blur(1rem);

    .hero-bubble-sm {
      width: 1.5rem;
      height: 1.5rem;
    }

    .hero-bubble-lg {
      width: 2.75rem;
      height: 2.75rem;
    }

    &:nth-child(odd) {
      animation: hero-bubble 20s infinite ease-in;
    }

    &:nth-child(even) {
      animation: hero-bubble 20s infinite ease-in reverse;
    }
  }
}

@keyframes hero-bubble {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(1.5) translateY(1rem);
  }

  50% {
    opacity: .5;
  }

  66% {
    transform: scale(.5) translateY(0);
  }

  100% {
    transform: scale(1);
    opacity: .2;
  }
}