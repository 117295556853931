.bottom-dropdown {
    position: fixed;
    bottom: 20px;
    left: 15px;
    width: $sidebar-width;
    z-index: 1033;
    box-shadow: 0 1px 2px rgb(220 225 232 / 50%), 0 1px 2px rgb(220 225 232 / 50%);
    border-radius: 4px;
    background-color: #fff;
    &__submenu {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .nav-main-link {
        &.active {
            background-color: rgba($primary,0.08);
            border-left: 2px solid $primary;
            color: $primary;
            font-weight: bold;

            &:hover {
                //color: darken($body-color, 15%);
            }
        }
    }
}

.sidebar-dark {
    .bottom-dropdown {
        background-color:  $sidebar-dark-bg;

        .nav-main-link {
            font-weight: 400;
            color: rgba($body-color-light, .75);
            &:hover, &:focus {
                background-color: shade-color($sidebar-dark-bg, 20%);
                color: rgba($body-color-light, .75);
            }
            &.active {
                color: $primary;
                font-weight: bold;
                &:hover, &:focus {
                    background-color: rgba($primary,0.08);
                }
            }
        }
    }
}