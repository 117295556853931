//
// Spacing
// --------------------------------------------------

@each $name, $value in $spacers {
  .space-x-#{$name} > * + * {
    margin-left: $value;
  }
}

@each $name, $value in $spacers {
  .space-y-#{$name} > * + * {
    margin-top: $value;
  }
}

