.image-upload {
    .block-content {
        min-height: 250px;
        position: relative;
    }
   
    .image-upload-after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        &:before {
            content: "";
            opacity: 0;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($orange-300,0.4);
            
        }
        &:after {
            content: "";
            opacity: 0;
            position: absolute;
            z-index: 2;
            left: 20px;
            top: 20px;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            background-color: rgba($blue-300,0.6);
            border-radius: 0.3rem;
        }
        &:hover {
            &:before, &:after, .hover-content {
                opacity: 1;
            }
        }
    }

    .hover-content {
        opacity: 0;
    }
    .file-upload-btn, .file-delete-btn {
        position: relative;
        z-index: 3;
    }
        
    #content_image-static {
        z-index: 3;
        opacity: 0;
    }
}
