div.dataTables_wrapper {
    div.dataTables_filter {
        text-align: left;
    }
}
.dataTables_length select {
    margin-right: 10px;
}
.dataTables_info {
    display: none;
}
.datatable-footer-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.dataTables_scrollHead, .dataTables_scrollBody {
    td, th {
        vertical-align: middle;
    }
    td {
        
        color: $custom-grey;
    }
}

table.dataTable {
    thead {
        .sorting_asc.aico__bulk-operation-column:after {
            content: none;
        }

        > tr {
            th.sorting {
                cursor: pointer;
            }
        }
    }
}
table.dataTable>tbody>tr.child span.dtr-title {
    font-weight: 500;
    color: $theme-pulse-darker;
}

table.dataTable thead > tr th {
    color: $theme-pulse-darker;
    text-transform: initial;
}
    

#orders-table_wrapper {
    .dataTables_scrollBody {
        position: static !important
    }
    .dataTables_scrollHead {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.table-dropdown .dataTables_scrollBody {
    position: static !important;
}