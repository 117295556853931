//these sidebar layout variation file is to override same layout on page-container
//main element which are done above lg breakpoint in default.
//by overriding below lg breakpoint we are not touching theme default file
//we added all declrations from default file and kept only related to sidebar-mini and changed to below lg. 
#page-container {

    & > #main-container > .content {
      max-width: 100%;
      
    }


    &.page-header-fixed,
    &.page-header-glass {
        @include media-breakpoint-down(lg) {
            
            // Mini Sidebar
            &.sidebar-mini.sidebar-o #page-header {
                padding-left: $sidebar-mini-width;
        
                .overlay-header {
                left: $sidebar-mini-width;
                }
            }
        }
    }
    
    // Side Scroll
    &.side-scroll {
        @include media-breakpoint-down(lg) {
          #sidebar {
            .content-header,
            .content-side {
              width: $sidebar-width !important;
            }
          }
    
          #side-overlay {
            .content-header,
            .content-side {
              width: $side-overlay-width !important;
            }
          }
        }
    }
      
  
    @include media-breakpoint-down(lg) {
      
        // Mini Sidebar
        &.sidebar-mini.sidebar-o {
          padding-left: $sidebar-mini-width;
        }
    
        
        &.sidebar-mini.sidebar-o #sidebar:not(:hover) .content-header {
          width: $sidebar-mini-width !important;
          margin-left: 0;
        }
    }
}
  
    
  
      
  
  
  
  
  