//homepage customisations
// .homepage {
//     .sidebar-submenu {
//         display: none;
//     }
//     [data-action="sidebar_text_toggle"] {
//         display: none;
//     }
// }


.sidebar-type-2 {
    .nav-main-link.nav-main-link-submenu::before {
        content: none;
    }
    
    .sidebar-expanded + .sidebar-submenu {
        left: $sidebar-width;
        @include media-breakpoint-down(sm) {
            left: $sidebar-smaller-width;
        }
    }
    
    .sidebar-submenu {
        $parent: &;
        position: fixed;
        z-index: 1032;
        left: $sidebar-mini-width;
        top: 0;
        height: 100%;
        
    
        .content-side {
            padding-top: 0;
            padding-bottom: 0;
        }
    
        .resizer {display: none}
    
        &__inner {
            padding: 1.5rem 0;
            height: 100%;
            width: $sidebar-width;
            display: none;
            border-right: 1px solid #ddd;
            background-color: #fff;
            @include media-breakpoint-down(sm) {
                width: $sidebar-smaller-width;
            }
        }
    
    
        &.sidebar-expanded {
            width: $sidebar-width;

            @include media-breakpoint-down(sm) {
                  width: $sidebar-smaller-width;
            }
    
            .sidebar-submenu__inner {
               display: block;
            }
                
    
            //.resizer {display:  block}
    
            .collapse-icon {
                right: -12.5px;
                left: auto;
                background-color: #fff;
                border: 1px solid #ddd;
                i {
                    transform: scaleX(-1);
                    color: $body-color;
                }
                &:hover i {
                    color: $primary;
                }
            }
        }
    }
    
    .nav-main-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-right: 10px;
        font-size: 0.875rem;
    }
    
    .nav-main {
        margin-bottom: 0;
    }
    
    .nav-main-link .nav-main-link-icon {
        min-width: 1.5rem;
        font-size: 1rem;
        margin-left: 1.5px;
        width: 36px;
        height: 36px;
        border-radius: 25%;
        display: flex;
        align-items:center;
        justify-content: center;
    }
    
    &.sidebar-mini:not(.sidebar-dark) {
        .nav-main-link {
            &:hover  {
                color: $primary; 
            }
        }
    
        .nav-main-link .nav-main-link-icon {
            background-color: var(--nav-main-link-icon-bg);
        }
        #sidebar {
            .nav-main-item.open {
                .nav-main-link  {
                    color: $primary;
                }
                .nav-main-link-icon {
                    background: $primary;
                    color: #fff;
                }
            }
            .nav-main-item.current {
                .nav-main-link  {
                    color: $primary;
                    font-weight: bold;
                }
                .nav-main-link-icon {
                    background: none;
                    color: $primary;
                }
            }
        }
    }
    
    .nav-main-submenu {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        background: none;
        
        .nav-main-link {
            margin-right: 10px;
            margin-left: 1px;
            padding: 0.5rem 0.75rem;
    
            &:not(.active):hover {
                background-color: $body-bg;
                color: lighten($body-color, 25%) !important;
            }
            
            &.active {
                background-color: rgba($primary,0.08);
                border-left: 2px solid $primary;
                color: $primary;
                font-weight: bold;
    
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}



.sidebar-type-1 {
    .nav-main-link {
        padding: 0 0.625rem;
        font-size: 0.8125rem;
        min-height: 2rem;

        &:hover .nav-main-link-icon, &:focus .nav-main-link-icon {
            color: lighten($body-color, 40%);
        }

        &:not(.active):hover {
            background-color: $body-bg;
            color: lighten($body-color, 5%);
        }
        &.active {
            background-color: rgba($primary,0.08);
            color: $primary;
            font-weight: bold;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                width: 3px;
                background: $primary;
                top: 0;
                height: 100%;
                border-radius: 0 3px 3px 0;
            }

            &:hover {
                color: $primary;
            }
        }
    }
    
    .nav-main-link .nav-main-link-icon {
        width: 40px;
        height: 32px;
        font-size: 13px;
        padding: 7px;
        margin-right: 0;
        display: flex;
        align-items:center;
        justify-content: center;
    }
    .nav-main-item.open {
        .nav-main-submenu {
            padding-top: 0;
            padding-bottom: 0;
        }
        & > .nav-main-link-submenu {
            &:hover {
                color: $black;
            }
            & > .nav-main-link-icon {
                color: lighten($body-color, 40%);
            }
        }

        &:not(.nav-main-link-submenu) {
            .nav-main-link {
                // background-color: rgba($primary,0.08);
                // color: $primary;
                // position: relative;
                // &-icon {
                //     color: $primary;
                // }
                // &:before {
                //     content: "";
                //     position: absolute;
                //     left: 0;
                //     width: 3px;
                //     background: $primary;
                //     top: 0;
                //     height: 100%;
                //     border-radius: 0 3px 3px 0;
                // }

                // &:hover {
                //     color: $primary;
                // }
            }
        }
    }
    .nav-main-item.current > .nav-main-link-submenu {
        color: $primary;
        font-weight: bold;
        .nav-main-link-icon {
            color: $primary;
        }
        &:hover {
            color: $primary; 
        }
    }
    .nav-main-submenu {
        background-color: #fff;
        padding-left: 0px;

        .nav-main-link {
            padding-left: 50px;
            &:not(.active):hover {
                color: lighten($body-color, 25%);
            }
        }
    }
}