// .sidebar-modern {
//     .nav-main-link:hover .nav-main-link-icon {
//         color: lighten($body-color, 40%);
//         background-color: #ddd;
//     }
// }

.has-sidebar-modern {
    [data-action="sidebar_text_toggle"] {
        display: none;
    }
}

.sidebar-main-wrapper:hover .sidebar-modern + .sidebar-submenu {
    .sidebar-submenu__inner {
       //display: block;
    }
}